import { Id } from 'types/common';
import { TagGroupsTypes } from 'types/scouting';
import { IdType } from 'vis-timeline';

export const API_ENDPOINTS = {
  PUBLIC: {
    LOGIN: 'login',
    LOGOUT: 'logout',
    REGISTER: 'register',
    FORGOT_PASSWORD: 'forgot-password',
    RESET_PASSWORD: 'reset-password',
    COUNTRIES: 'countries',
    GET_TAGS_OPERATIONS: `public/tags/operations`,
    GET_PUBLIC_SCOUTING: (id: Id, includes: Array<String> = []) => {
      const parsedIncludes = includes.length
        ? includes.reduce((acc, val) => `${acc},${val}`)
        : '';
      return `public/scoutings/${id}?include=${parsedIncludes}`;
    },
    RESELLER_CODE_EXISTS: (code: String) => `reseller/${code}`,
    USERNAME_EXISTS: (username: String) => `auth/username/${username}`,
    SECTIONS: 'sections?include=skouting',
    SPECIFIC_SECTION: (id: number, search: String) =>
      `section/${id}?include=skouting&search=${search}`,
  },
  PRIVATE: {
    UPDATE_SCOUTING_PUBLIC_STATUS: (id: Id) => `scoutings/${id}/publish`,
    UPDATE_SCOUTINGS: (id: Id) => `scoutings/${id}?_method=PUT`,
    UPDATE_SCOUTINGS_ACTIONS: (id: Id) => `scoutings/${id}/actions`,
    SAVE_SCOUTINGS: 'scoutings',
    GET_SCOUTINGS: 'scoutings',
    GET_SCOUTINGS_BY_USER: (user_id: Id) => `scoutings-by-user/${user_id}`,
    GET_MY_SCOUTINGS: 'my-scoutings',
    GET_SCOUTING: (id: Id, includes: Array<String> = []) => {
      const parsedIncludes = includes.length
        ? includes.reduce((acc, val) => `${acc},${val}`)
        : '';
      return `scoutings/${id}?include=${parsedIncludes}`;
    },
    GET_SHORT: (user_id: Id, scouting_id: Id, action_id: Id) => {
      return `get-short/${user_id}/scouting/${scouting_id}/action/${action_id}`;
    },
    CREATE_SHORT: 'create-short',
    UPDATE_SHORT: (id: Id) => `update-short/${id}`,
    GET_PUBLIC_SCOUTINGS: 'public/scoutings',
    GET_CATEGORIES: 'categories',
    GET_SCOUTINGS_STATISTICS: 'scoutings/statistics',
    GET_SCOUTINGS_TAGS: 'tags',
    GET_SCOUTINGS_PROTAGONISTS: 'protagonists',
    GET_SHARED_WITH_ME_SCOUTINGS: 'scoutings/shared-with-me',
    GET_USER: '/user',
    GET_USER_DATA: '/user-data',
    GET_IS_TRIAL_EXPIRED: 'user/trial-expired',
    GET_USER_PLAN: '/user/plan?include=plan',
    USERNAME_EXISTS: (username: String) => `user/username/${username}`,
    UPDATE_USER: 'user',
    CHANGE_PASSWORD: 'user/change-password',
    GET_SPORTS: 'sports?include=specialties',
    UPLOAD_PHOTO: 'user/upload-photo',
    UPLOAD_BANNER_PHOTO: 'user/upload-banner-photo',
    POST_ACTION_COMMENT: (scoutingId: Id, actionId: Id) =>
      `scoutings/${scoutingId}/actions/${actionId}/comments`,
    POST_COMMENT: (scoutingId: Id) => `scoutings/${scoutingId}/comments`,
    GET_PROTAGONISTS: (groups: TagGroupsTypes[]) =>
      `protagonists?groups=${groups.join(',')}`,
    GET_PROTAGONIST_GROUP: (tagId: Id) => `protagonists/group/${tagId}`,
    GET_TAGS: (groups: TagGroupsTypes[]) => `tags?groups=${groups.join(',')}`,
    GET_TAGS_OPERATIONS: `tags/operations`,
    GET_QUICK_ACTIONS_USER: 'user/quick-actions',
    POST_QUICK_ACTIONS: 'user/quick-actions',
    PUT_QUICK_ACTIONS: (scoutingQuickActionId: Id) =>
      `user/quick-actions/${scoutingQuickActionId}?include=allTags.tags`,
    POST_TAGS: 'tags',
    POST_PROTAGONIST_GROUP: 'protagonists/group',
    POST_PROTAGONIST: 'protagonists',
    PUT_PROTAGONIST: (tagId: Id) => `protagonists/${tagId}`,
    PUT_PROTAGONIST_GROUP: (id: Id) => `protagonists/group/${id}`,
    PUT_TAG: (tagId: Id) => `tags/${tagId}`,
    DELETE_PROTAGONIST: (protagonistId: Id) => `protagonists/${protagonistId}`,
    DELETE_PROTAGONISTS: `protagonists/`,
    DELETE_TAG: (tagId: Id) => (tagId ? `tags/${tagId}` : `tags/`),
    DELETE_TAGS: `tags/`,
    DELETE_SCOUTINGS: (id?: Id) => (id ? `scoutings/${id}` : `scoutings/`),
    GET_USER_GROUPS: 'user/groups/',
    GET_USER_GROUPS_FIND: (id: Id) => `user/groups/${id}`,
    GET_USER_GROUPS_INVITATIONS: 'user/groups/invitations',
    PUT_USER_GROUPS_INVITATIONS: (invitationId: Id) =>
      `user/groups/invitation/${invitationId}?include=userGroup.user`,
    DELETE_USER_GROUP: (groupId: number) => `user/groups/${groupId}`,
    DELETE_USER_GROUP_MEMBER: (groupId: number, memberId: number) =>
      `user/groups/${groupId}/members/${memberId}`,
    GET_USER_GROUPS_LIST: 'user/groups/list',
    POST_USER_GROUPS: 'user/groups/',
    PUT_USER_GROUPS: (id: Id) => `user/groups/${id}`,
    POST_PAYMENT: 'payments/',
    UPGRADE_PAYMENT: 'payments/upgrade',
    GET_PAYMENT_PRORATE: (id: Id) => `/payments/prorate/${id}`,
    POST_PAYMENT_INVOICE_AI: 'payments/invoice/ai',
    GET_PLANS: 'plans',
    GET_PLAN: (id: string) => `plans/${id}`,
    GET_LICENSES: (status: string) => `licenses/${status}`,
    POST_ZOHO: 'zoho',
    GET_ALGORITHMS: 'algorithms/',
    UPDATE_ALGORITHMS: (algorithm: IdType) => `algorithms/${algorithm}`,
    SHARE_SCOUTING: (scoutingId: IdType) => `scoutings/${scoutingId}/share`,
    SHARE_SCOUTING_WITH_GROUP: (scoutingId: IdType) =>
      `scoutings/${scoutingId}/group-share`,
    UNSHARE_SCOUTING: (scoutingId: IdType) =>
      `scoutings/${scoutingId}/share/destroy`,
    SEND_MAIL_ERROR: 'send/mail',
    GET_IS_FREE_LICENSE: (userId: IdType, category: string) =>
      `payments/isFreeLicense/${userId}/${category}`,
    GET_NOTIFICATIONS: 'notifications',
    READ_NOTIFICATIONS: 'notifications/read',
    GET_NOTIFICATIONS_PREVIEW: 'notifications/preview',
    GET_UNREAD_NOTIFICATIONS_COUNT: 'notifications/unread/count',
    GET_USER_PAYMENTS: 'payments/',
    POST_REVOKE_LICENSE: `licenses/revoke`,
    POST_RUN_ALGORITHM: `algorithm/run`,
    GET_ALGORITHMS_RUNS: `algorithm/runs`,
    MAKE_PAYMENT: `payments/make`,
    CHANGE_SUBSCRIPTION: `payments/update-subscription`,
    BILLING_URL: `/payments/billing`,
    DOWNLOAD_PROCESS: `/payments/finish-subscription-message`,
    SIGN_VIDEO: 'videos/signed-url',
    POST_SECTION: 'section',
    PUT_SECTION: (id: number) => `section/${id}`,
    DELETE_SECTION: (id: number) => `section/${id}`,
    SECTION_BY_USER: (search: String) =>
      `user/sections?include=skouting&search=${search}`,
    POST_SECTION_FILL: (id: number) =>
      `section/skoutings/${id}?include=skouting`,
    GET_FILTERS: 'filters',
    ADD_SUBSCRIBER: (owner_id: IdType) =>
      `subscriptions/add-subscriber/${owner_id}`,
    REMOVE_SUBSCRIBER: (owner_id: IdType) =>
      `subscriptions/remove-subscriber/${owner_id}`,
    GET_SUBSCRIBER: (username: string | undefined) =>
      `subscriptions/get-subscriber/${username}`,
    UPDATE_CHANNEL_DESCRIPTION: 'subscriptions/update-channel',
    SHORTS: (user_id: IdType, per_page: IdType, page: IdType) =>
      `shorts/${user_id}?per_page=${per_page}&page=${page}`,
    GET_TRANSACTION_HISTORY: 'transaction-history',
  },
  YOUTUBE: {
    METADATA: (videoId: string) =>
      `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=snippet,contentDetails,status&maxResults=50&pageToken=&key=${process.env.REACT_APP_YOUTUBE_KEY}`,
  },
};
